import { AxiosError, AxiosResponse } from 'axios';

export const createErrorMessage = (error: AxiosError, defaultMessage: string) => {
  const { errors, message } = (error.response as AxiosResponse).data;

  const messages: string[] = [];

  if (message) messages.push(message);

  (Object.keys(errors || { error: undefined }) as (keyof string)[]).forEach((key) => {
    if (errors && errors[key]) messages.push(errors[key]);
  });

  return messages.length > 0 ? messages.join(', ') : defaultMessage;
};
